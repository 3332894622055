
import { Component, Prop, Vue } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";

import {
  IEntityFacetField,
  IEntityFilter,
} from "@/interfaces/entity-filter.interface";

import { isEntityFilterActive } from "@/utils/entity";

import Dialog from "@/components/shared/Dialog.vue";
import CustomFilterFacetPanel from "@/components/entity/filter/CustomFilterFacetPanel.vue";
import EntityFilterFacetPanel from "@/components/entity/filter/EntityFilterFacetPanel.vue";

interface Data {
  menuFrom: boolean;
  menuTo: boolean;
  fromDate: string;
  toDate: string;
  panels: number[];
}

@Component({
  components: {
    Dialog,
    EntityFilterFacetPanel,
    CustomFilterFacetPanel,
  },
  computed: {
    ...mapGetters([
      "searchTopicFacets",
      "getAuthorities",
      "getCredentialTypes",
      "getRegistrationTypes",
      "getEntityFilters",
      "mdiCalendar",
      "mdiInformationOutline",
    ]),
  },
  methods: {
    ...mapActions(["setFilter"]),
  },
})
export default class EntityFilterFacetPanels extends Vue {
  @Prop({ default: "" }) entityType!: string;
  getAuthorities!: Array<IEntityFacetField>;
  getCredentialTypes!: Array<IEntityFacetField>;
  getRegistrationTypes!: Array<IEntityFacetField>;
  getEntityFilters!: IEntityFilter;
  setFilter!: (filter: IEntityFilter) => void;
  isEntityFilterActive: (
    filterField: string,
    getEntityFilters: IEntityFilter,
    filterString?: string
  ) => boolean = isEntityFilterActive;
  data(): Data {
    return {
      menuFrom: false,
      menuTo: false,
      fromDate: "",
      toDate: "",
      panels: [0, 1, 2, 4],
    };
  }
  toggleShowExpired(newVal: boolean): void {
    var currFilters = { ...this.getEntityFilters };
    currFilters.show_expired = !newVal;
    this.setFilter(currFilters);
  }
  resetMinDate(): void {
    var currFilters = { ...this.getEntityFilters };
    currFilters.date_min = "";
    this.setFilter(currFilters);
  }
  resetMaxDate(): void {
    var currFilters = { ...this.getEntityFilters };
    currFilters.date_max = "";
    this.setFilter(currFilters);
  }
  handleMinDateChange(newVal: string): void {
    var currFilters = { ...this.getEntityFilters };
    currFilters.date_min = newVal;
    this.setFilter(currFilters);
  }
  handleMaxDateChange(newVal: string): void {
    var currFilters = { ...this.getEntityFilters };
    currFilters.date_max = newVal;
    this.setFilter(currFilters);
  }
}
