
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class EntityHeader extends Vue {
  @Prop({ default: "" }) name!: string;
  @Prop({ default: "" }) businessNumber!: string;
  @Prop({ default: "" }) jurisdiction!: string;
  @Prop({ default: "" }) state!: string;
}
