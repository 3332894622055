
import { Component, Prop, Vue } from "vue-property-decorator";
import { mapGetters, mapActions } from "vuex";
import {
  IEntityFacetField,
  IEntityFilter,
} from "@/interfaces/entity-filter.interface";
import { translate } from "@/i18n/translate";
import { isEntityFilterActive, toTranslationFormat } from "@/utils/entity";

@Component({
  computed: {
    ...mapGetters(["getEntityFilters"]),
  },
  methods: {
    ...mapActions(["toggleEntityFilter"]),
  },
  directives: {
    translate,
  },
})
export default class EntityFilterFacets extends Vue {
  @Prop({ default: "" }) entityType!: string;
  @Prop({ default: () => [] }) filterField!: string;
  @Prop({ default: () => [] }) fields!: IEntityFacetField[];

  getEntityFilters!: IEntityFilter;
  toTranslationFormat = toTranslationFormat;
  isEntityFilterActive: (
    filterField: string,
    getEntityFilters: IEntityFilter,
    filterString?: string
  ) => boolean = isEntityFilterActive;
}
