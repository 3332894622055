
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class EntityCard extends Vue {
  @Prop({ default: false }) expanded!: boolean;
  @Prop({ default: false }) timeline!: boolean;

  get panels(): number[] {
    return this.expanded
      ? [...Array(this.$slots.expansionPanels?.length).keys()]
      : [];
  }
}
